import React, {useState, useEffect} from 'react'; // , useEffect, useCallback, useRef
import Sheet from "react-modal-sheet";
import './Table.css'
// import audioguide from './../image/audioguide.svg'
import ticket from './../image/ticket2.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";
import {useAuthHeader, useAuthUser} from "react-auth-kit";
import axios from "axios";
import FileDownload from "js-file-download";
import {isMobile} from 'react-device-detect';
import randomBytes from 'randombytes';
// import {PDFPreview} from "../PDFPreview/PDFPreview";

import useWebSocket from 'react-use-websocket';

export function Modal({isOpen, onClose, currentEvent, guideMode}) {

    // console.log('isOpen2', isOpen, 'currentEvent', currentEvent)

    const [downloading, setDownloading] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [changedItems, setChangedItems] = useState([]);

    // const [messages, setMessages] = useState([]);
    const [sid, setSid] = useState(null);
    const [tour, setTour] = useState(null);
    const auth = useAuthUser()

    const authHeader = useAuthHeader()
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkaXNwbGF5X25hbWUiOiJEZWVTZWUiLCJleHAiOjE3MTAxODMwMzQsImlkIjoxLCJvcmlnX2lhdCI6MTcwOTU3ODIzNCwicm9sZSI6ImFkbWluIn0.REnhRV0sP4GnkA0d4y4C4h40bzxqTF57lZtBwywRmyw"
    const token = authHeader().replace("Bearer ", "")

    const socketUrl = axios.defaults.baseURL.replace("http", "ws")+"/ws?token="+token+"&sid="+sid+"&tour="+tour

    const handleWSMessage = (event) => {
        console.log("handleWSMessage", event)
        if (event.data === "pong") {
            return
        }
        console.log("handleWSMessage event.data", JSON.parse(event.data))
        const lastJsonMessage = JSON.parse(event.data)

        if (lastJsonMessage.Detail && lastJsonMessage.Detail.Checked) {
            // if (!checkedItems.includes(lastJsonMessage.NameKey)) {
            setCheckedItems((prevCheckedItems) => [...prevCheckedItems, lastJsonMessage.NameKey]);

            console.log("changedItems", changedItems)
            // if (!changedItems.includes(lastJsonMessage.NameKey)) {
                setChangedItems((prevChangedItems) => [...prevChangedItems, lastJsonMessage.NameKey]);

                setTimeout(() => {
                    setChangedItems((prevChangedItems) => prevChangedItems.filter((item) => item !== lastJsonMessage.NameKey));
                }, 150);
            // }
            // }
        } else {
            setCheckedItems((prevCheckedItems) => prevCheckedItems.filter((item) => item !== lastJsonMessage.NameKey));
        }
    }
    const { sendMessage } = useWebSocket(socketUrl,
        {
            onMessage: handleWSMessage,
            shouldReconnect: (closeEvent) => true,
            retryOnError: true,
            share: true,
            heartbeat: {
                message: 'ping',
                returnMessage: 'pong',
                timeout: 60000, // 1 minute, if no response is received, the connection will be closed
                interval: 25000, // every 25 seconds, a ping message will be sent
            },
        }
    );

    useEffect(() => {
        setSid(randomBytes(20).toString('hex'))
        setTour(currentEvent.tour+"_"+currentEvent.guide+"_"+moment(currentEvent.start).format("YYYY-MM-DD_HH:mm"))
    }, [currentEvent]);

    const getAccess = (event) => {
        // console.log('event click', new Date(event.start))
        let romeTime = new Date(event.start).getTime()
        romeTime.toLocaleString('en-US', { timeZone: 'Europe/Rome' })
        let currTime = new Date().getTime()
        const hoursDiff = Math.floor((romeTime - currTime)/(3600*1000))

        console.log('hoursDiff (romeTime - currTime)', hoursDiff)
        console.log('auth()', auth())
        return auth()?.role === 'admin' || hoursDiff < 72
    }
    const data = {
        tour: currentEvent.tour,
        date: moment(currentEvent.start).format("YYYY-MM-DD"),
        filename: currentEvent.filename,
        datetime: moment(currentEvent.start).format("YYYY-MM-DD HH:mm"),
        guide: currentEvent.guide,
    };

    const searchParams = new URLSearchParams(data);
    const url = '/api/voucher?'+searchParams

    const handleDownload = (event) => {
        setDownloading(true)
        console.log("handleClick currentEvent", currentEvent)

        axios({
            url: url, //your url
            method: 'GET',
            responseType: 'blob', // important
        })
            .then((response) => {
                const filename = currentEvent.tour + "_" + moment(currentEvent.start).format("YYYY-MM-DD_HH:mm") + ".pdf"
                if(isMobile) {
                    FileDownload(response.data, filename);
                } else {
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], { type: "application/pdf" });
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);

                    // // 1) Open the URL on new Window
                    const pdfWindow = window.open();
                    pdfWindow.location.href = fileURL;

                    // // 2) create <a> tag dinamically
                    // var fileLink = document.createElement('a');
                    // fileLink.href = fileURL;
                    // //it forces the name of the downloaded file
                    // fileLink.download = filename;
                    // //triggers the click event
                    // fileLink.click();
                }
                setDownloading(false)
            }).catch((e) => {
                setDownloading(false)
            })
    }

    const getTotalGuests = () => {
        return currentEvent.owners.reduce(function(previousValue, currentValue) {
            return previousValue + currentValue.adults + (currentValue.childs > 0 ? parseInt(currentValue.childs) : 0)
        }, 0)
    }
    const getTotalPrice = () => {
        let price = currentEvent.owners.reduce(function(previousValue, currentValue) {
            return previousValue + currentValue.surcharge
        }, 0)
        return price ? price + ' €' : '0 €'
    }
    console.log("currentEvent", currentEvent)

    const handleChange = (event, names) => {
        console.log("event", event, event.target.checked, 'names', names, 'checkedItems', checkedItems)
        // const { name, checked } = event.target;

        if (!guideMode) {
            return
        }

        let payload = data
        payload["names"] = names.split(';')
        payload["sid"] = randomBytes(20).toString('hex')

        if (checkedItems.filter((item) => item === names).length > 0) {
            payload["check"] = false
            setCheckedItems(checkedItems.filter((item) => item !== names));
        } else {
            payload["check"] = true
            setCheckedItems([...checkedItems, names]);
        }
        //
        // if (checked) {
        //     setCheckedItems([...checkedItems, name]);
        // } else {
        //     setCheckedItems(checkedItems.filter((item) => item !== name));
        // }
        sendMessage(JSON.stringify(payload))
    }
    if (currentEvent === null) {
        return <></>
    }
    return (
        <Sheet isOpen={isOpen} onClose={() => onClose()}>
            <Sheet.Container>
                <Sheet.Header key="Sheet.Header">
                    <div className='modal-container'>
                        <h2>{currentEvent.tour === 'VAT' ? 'Vatican' : (currentEvent.tour === 'COL' ? 'Colosseum' : currentEvent.tour) } ({moment(currentEvent.start).format("ddd, MMM D, HH:mm")})</h2>
                    </div>
                </Sheet.Header>
                <Sheet.Content key="Sheet.Content" style={{overflowY: 'scroll'}}>
                    {getAccess(currentEvent) ? (
                        <>
                            {!guideMode &&
                                <div className='modal-container' style={{paddingTop: 0, paddingBottom: 0}}>
                                    Guide: {currentEvent.guide}
                                </div>
                            }
                            {/*{currentEvent.tour === 'COL' && currentEvent.language === 'US' &&*/}
                            {/*    // <div className='modal-container'>*/}
                            {/*    <Card bg='light' style={{margin: '0 20px'}}>*/}
                            {/*        <Card.Body>*/}

                            {/*            <Container padding="0px">*/}
                            {/*                <Row>*/}
                            {/*                    <Col key="1" xs={3} align="center" style={{padding: 0}}>*/}
                            {/*                        <img src={audioguide} className="audioguide" alt="audioguide"/>*/}
                            {/*                    </Col>*/}
                            {/*                    <Col key="2">Headphones are reserved for {currentEvent.sell} people.*/}
                            {/*                        Pick up*/}
                            {/*                        from*/}
                            {/*                        Cafe ROMA 15 minutes before the*/}
                            {/*                        start of the tour.</Col>*/}
                            {/*                </Row>*/}
                            {/*            </Container>*/}

                            {/*        </Card.Body>*/}
                            {/*    </Card>*/}
                            {/*    // </div>*/}
                            {/*}*/}
                            <div className='modal-container'>

                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: '100%'}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell key="1" size='small' padding="none"></TableCell>
                                            <TableCell key="2" padding='none'>Name</TableCell>
                                            <TableCell key="3" align="center">Guests</TableCell>
                                            <TableCell key="4" align="center">On site</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {currentEvent.owners && (
                                            <>
                                                {currentEvent.owners.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{'&:last-child td, &:last-child th': {border: 0},
                                                            background: changedItems.includes(row.names.join(";")) ? '#198754' : 'white',
                                                            transition: "background 150ms ease-in-out",
                                                        }}
                                                        onClick={(e) => handleChange(e, row.names.join(";"))}

                                                    >
                                                        <TableCell key="1" size='small' padding="checkbox"
                                                                   component="th"
                                                                   scope="row"
                                                                   width={10}>
                                                            <Form.Check // prettier-ignore
                                                                type='checkbox'
                                                                id='default-checkbox'
                                                                name={row.names.join(";")}
                                                                label=''
                                                                size="lg"

                                                                checked={checkedItems.includes(row.names.join(";"))}
                                                                // checked={lastJsonMessage && lastJsonMessage.NameKey === row.names.join(";")}
                                                                disabled={!guideMode}
                                                                style={{paddingLeft: '1.5em', fontSize: '1.175rem'}}
                                                                onClick={(e) => handleChange(e, row.names.join(";"))}
                                                            />
                                                        </TableCell>
                                                        <TableCell key="2" padding='none' component="th"
                                                                   scope="row">
                                                            <div style={{padding: "7px 0"}}>
                                                                {row.names.map((name, i) => (
                                                                    <div
                                                                        key={i}>{!guideMode && i === 0 && row.remind && "⚠️ "}{name}</div>
                                                                ))}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell key="3" align="center">
                                                            {row.adults}
                                                            {row.childs ? '+' + row.childs : ''}
                                                            {row.childAges &&
                                                                <div
                                                                    style={{color: '#adb5bd'}}>{row.childAges}</div>}
                                                        </TableCell>
                                                        <TableCell key="4" align="center">
                                                            {row.surcharge ? row.surcharge : '0'} €
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow key="total">
                                                    <TableCell key="1" rowSpan={1}/>
                                                    <TableCell key="2" align="right">Total</TableCell>
                                                    <TableCell key="3"
                                                               align="center">{getTotalGuests()}</TableCell>
                                                    <TableCell key="4"
                                                               align="center">{getTotalPrice()}</TableCell>
                                                </TableRow>
                                            </>
                                        )}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            {currentEvent.tour === 'VAT' &&
                                <div className='modal-container' style={{paddingTop: 0}}>
                                    🎧 Whisper’s emergency contacts:
                                    Outside Musems <a href="tel:+393351266091" className="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">+393351266091</a> Inside
                                    Musems <a href="tel:+390669865113" className="link-success  link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">+390669865113</a>
                                </div>
                            }
                            {currentEvent.tour === 'VAT' &&
                                <div className='modal-container' style={{paddingTop: 0}}>
                                    📍Meet your guests at Viale Vaticano 100 corner with Caffè Vaticano 10 minutes
                                    before the tour starts
                                </div>
                            }
                            {currentEvent.tour === 'COL' &&
                                <div className='modal-container' style={{paddingTop: 0}}>
                                    🎧 When you have 6+ guests in your group please pickup the radios from Albavoice staff at the meeting point.
                                    Albavoice’s emergency contacts: <a href="tel:+393519417518" className="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">+393519417518</a> or <a href="tel:+393518210218" className="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">+393518210218</a>
                                </div>
                            }
                            {currentEvent.tour === 'COL' &&
                                <div className='modal-container' style={{paddingTop: 0}}>
                                    📍Meet your guests at Largo G. Agnesi 1 in front of Caffè Roma 10 minutes before
                                    the tour starts
                                </div>
                            }
                            {currentEvent.tour === 'GTT' &&
                                <div className='modal-container' style={{paddingTop: 0}}>
                                    📍Meet your guests at Piazza G. G. Belli near the monument 10 minutes before the tour starts
                                </div>
                            }
                            {currentEvent.filename !== "" &&
                                <div className='modal-container'>
                                    <Button variant="success" size="sm" onClick={handleDownload}
                                            disabled={downloading}>
                                        {!downloading ? (
                                            <>
                                                <img src={ticket} width="30px" className="ticket" alt="ticket"
                                                     style={{filter: 'invert(1)'}}/>
                                                <span> Tickets</span>
                                            </>
                                        ) : (
                                            <>
                                                <Spinner
                                                    as="img"
                                                    animation="border"
                                                    size="sm"
                                                    width="20px"
                                                    role="status"
                                                    aria-hidden="true"
                                                    style={{verticalAlign: "sub"}}
                                                />
                                                <span style={{
                                                    verticalAlign: "unset",
                                                    lineHeight: "30px",
                                                    paddingLeft: "6px"
                                                }}> Loading...</span>
                                            </>
                                        )}
                                    </Button>
                                    {/*{!guideMode &&
                                        <PDFPreview url={url}></PDFPreview>
                                    }*/}
                                </div>
                            }

                        </>
                    ) : (
                        <div className='modal-container'>
                            Information about your guests and tickets will be available 72 hours before the start of
                            the tour
                        </div>
                    )}
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop/>
        </Sheet>
    )
}


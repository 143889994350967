import React, {useEffect} from 'react';
import {Stack} from "react-bootstrap";
import './Reviews.css'
import logo from "../image/logo.webp";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useAuthHeader, useAuthUser} from "react-auth-kit";
import axios from "axios";
import {Dialog} from "@mui/material";
import Chart from "./Chart";
import { useLocalStorage } from "../hooks/localstorage"
import Pluralize from 'react-pluralize'
// import Moment from "moment/moment";

export function Reviews() {

    const [guide, setGuide] = useLocalStorage('guide', '');
    const [year, setYear] = useLocalStorage('year', '2024');
    // const [guide, setGuide] = React.useState('');
    const [guideList, setGuideList] = React.useState([]);

    const auth = useAuthUser(null)
    const authHeader = useAuthHeader()

    useEffect(() => {
        if (auth()?.role === 'guide') {
            setGuide(auth()?.display_name);
            return
        }

        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/guides")
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    setGuideList(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, []);

    const [rows, setRows] = React.useState([]);

    // const authHeader = useAuthHeader()
    useEffect(() => {
        if (guide === '') {
            return
        }
        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/reviews?guide=" + guide + "&year=" + year)
            .then((response) => {
                if (response.data && response.data.length >= 0) {
                    setRows(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, [guide, year]);

    const handleChange = (event: SelectChangeEvent) => {
        setGuide(event.target.value);
    };
    const handleYearChange = (event: SelectChangeEvent) => {
        setYear(event.target.value);
    };
    const [selectedImg, setSelectedImg] = React.useState(null);

    const handleClose = () => setSelectedImg(null);

    return (
        <div className="reviews">
            <Stack direction="horizontal" style={{padding: '10px 0px'}} gap={1}>
                <div className="p-1 col-1">
                    <img src={logo} className="logo" alt="Emotion.club" width={50}/>
                </div>
                <div className="p-1 col-11">
                    <h2 className="text-center">Reviews</h2>
                </div>
            </Stack>
            {auth()?.role === 'admin' &&
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label2">Guide</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label2"
                        id="demo-simple-select-standard2"
                        value={guide}
                        onChange={handleChange}
                        label="Period"
                    >
                        {guideList.map((guide, index) => {
                            return (<MenuItem value={guide}>{guide}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            }
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label2">Year</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label2"
                    id="demo-simple-select-standard2"
                    value={year}
                    onChange={handleYearChange}
                    label="Period"
                >
                    {["2024", "2025"].map((year, index) => {
                        return (<MenuItem key={year} value={year}>{year}</MenuItem>)
                    })}
                </Select>
            </FormControl>
            {auth()?.role === 'admin' &&
                <Chart guide={guide} year={year} reviews={rows.reduce((acc, item) => acc + item.reviews.length, 0)}></Chart>
            }
            <Box sx={{width: '100%', height: '100%', overflowY: 'scroll'}}>
                {rows.map((item) => (
                    <>
                        <ImageList variant="masonry" cols={1} gap={8}>
                            <ImageListItem key="Subheader" cols={1}>
                                <ListSubheader component="div">{item.date} (<Pluralize singular={'review'} count={item.reviews.length} />)</ListSubheader>
                            </ImageListItem>
                        </ImageList>
                        <ImageList variant="masonry" cols={3} gap={8}>
                            {item.reviews.map((item) => (
                                <>
                                <ImageListItem key={item.img} style={{border: "1px black"}}>
                                    <img
                                        src={`https://lh3.googleusercontent.com/d/${item.screenshot_id}=w1000?authuser=1/view`}
                                        alt={item.channel}
                                        loading="lazy"
                                        onClick={() => {
                                            setSelectedImg(item.screenshot_id);
                                        }}
                                    />
                                </ImageListItem>
                                <Dialog
                                    onClose={handleClose}
                                    open={item.screenshot_id === selectedImg}
                                >
                                    <img
                                        src={`https://lh3.googleusercontent.com/d/${item.screenshot_id}=w1000?authuser=1/view`}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                </Dialog>
                                </>
                            ))}
                        </ImageList>

                    </>
                ))}
            </Box>
        </div>
    )
}

import React, {useEffect} from 'react';
import './Chart.css'
import {useAuthHeader} from "react-auth-kit";
import axios from "axios";
import Moment from "moment";
import { BarChart } from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';

export default function Chart({guide, reviews, year}) {

    const [reviewsChart, setReviewsChart] = React.useState([]);

    const authHeader = useAuthHeader()

    useEffect(() => {
        if (guide === '') {
            return
        }
        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/reviews_chart?guide=" + guide + "&year=" + year)
            .then((response) => {
                if (response.data && response.data.length >= 0) {

                    response.data.forEach(function(item){
                        item.enabled = true;
                    });
                    setReviewsChart(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, [guide, year]);

    let greyRule = "& .MuiBarElement-root:nth-of-type(n+"+(Moment(new Date()).format('M')-3)+")"

    const valueFormatter = (value: number | null) => `${value}`;

    if (reviewsChart.length === 0) {
        return
    }

    return (
        <div style={{width: '100%', marginTop: '50px'}}>
            {reviewsChart.filter(v => v.key === "Year")[0].value === 0 ? (
                <h5>🌟 There are no reviews for my tours yet</h5>
            ) : (
                <h5>🌟 My tours are rated {reviewsChart.filter(v => v.key === "Year")[0].value} out of 5 based on {reviews} reviews</h5>
                )
            }
            {reviewsChart.filter(v => v.key === "Team")[0].value !== 0 &&
                <Box sx={{flexGrow: 1}}>
                    <BarChart
                        tooltip={{trigger: 'item'}}
                        margin={{left: 0, right: 0, top: 0, bottom: 20}}
                        dataset={reviewsChart}
                        yAxis={[
                            {
                                hideTooltip: true,
                                tickNumber: 1,
                            }
                        ]}
                        xAxis={[
                            {
                                scaleType: 'band',
                                dataKey: 'key',
                                hideTooltip: true,
                                disableTicks: true,
                            }
                        ]}
                        position={"top"}
                        barLabel={(item, context) => {
                            return context.bar.height < 30 ? null : item.value?.toString();
                        }}
                        sx={{
                            "& .MuiBarElement-root": {
                                fill: "#8EC975",
                            },
                            "& .MuiChartsAxis-tickLabel": {
                                fontFamily: `"Montserrat", sans-serif`,
                                fontSize: "9px",
                            },
                            "& .MuiBarLabel-root": {
                                fontFamily: `"Montserrat", sans-serif`
                            },
                            [greyRule]: {
                                fill: "#e0e0e0",
                            },
                            "& .MuiBarElement-root:nth-last-of-type(2)": {
                                // fill: "#38761d",
                                fill: "#6aa84f",
                            },
                            "& .MuiBarElement-root:last-of-type": {
                                fill: "#ff6d01",
                            },
                            "& .MuiChartsTooltip-root": {
                                display: "none",
                            },
                            "& .MuiChartsAxis-directionY": {
                                display: "none",
                            },
                        }}
                        series={[{dataKey: 'value', valueFormatter}]}
                        height={200}
                    />
                </Box>
            }
        </div>
    )
}
